<template>
  <div class="main-styles">
    <p>
      网球<br />
      一般规则<br />1、如果比赛场地的表面有变更，所有投注的注单将被取消。
    </p>
    <p>2、所有投注的注单均在比赛结束后才被视为有效，除非另有注明。</p>
    <p>3、如果投注的球员没有参与比赛，所有投注此球员的注单将被取消。</p>
    <p>
      4、如果一名球员或组合在比赛未结束前退出或被取消资格，所有该场比赛的注单将被取消，除非另有注明。
    </p>
    <p>5、如果比赛预定时间缩短或者需获胜比赛的比分提高，所有注单将被取消。</p>
    <p>6、如果赛事延期或中断，只要比赛最后有完成，所有投注将保持有效。</p>
    <p>
      7、如果赛事开出单局或单盘的盘口，而球员在赛事的某局或某盘退出或被取消资格，所有投注此局或此盘的注单将被取消，除非另有注明。
    </p>
    <p>
      8、如果比赛在法定时间提前进行，在比赛开始前的投注依然有效，在比赛开始后的所有投注均视为无效(滚球投注另作别论)。
    </p>
    <p>
      9、网球大满贯（四大公开赛：澳洲网球公开赛、美国网球公开赛、法国网球公开赛、温布顿网球公开赛），比赛结算方式:<br />&nbsp;
      &nbsp; &nbsp;9.1、男子组比赛：五盘中先赢三盘的球员，为胜方。<br />&nbsp;
      &nbsp; &nbsp;9.2、女子组比赛：三盘中先赢两盘的球员，为胜方。
    </p>
    <p></p>
    <p>
      投注类型<br />独赢盘 / 优胜者<br />1、预测哪一位球员将在比赛胜出。盘口提供两位球员为投注选项。
    </p>
    <p><br />让盘盘<br />1、预测哪一位球员在盘口指定的让盘数赢得比赛。</p>
    <p>
      <br />让局盘<br />1、预测哪一位球员在盘口指定的让局数在某个时节（例如:全场比赛/第一盘/第二盘）赢得最多局。
    </p>
    <p>2、决胜局(抢七局)将视为一局。</p>
    <p>
      <br />大/小（局）<br />1、预测比赛中的某个时节（例如:全场比赛/第一盘/第二盘）进行的总局数将大于或小于在盘口指定的大/小盘局数。
    </p>
    <p>2、决胜局(抢七局)将视为一局。</p>
    <p>
      3、如果比赛中断前已有明确结果并且之后没有任何显著会影响赛事结果的情况，大/小盘注单才会被结算。若遇到任何其他情况，注单将一律被取消。
    </p>
    <p>
      <br />单/双<br />1、预测比赛中的某个时节（例如:全场比赛/第一盘/第二盘）进行的总局数是单数或双数。
    </p>
    <p>2、决胜局(抢七局)将视为一局。</p>
    <p><br />单盘投注<br />1、预测单盘最终结果。</p>
    <p>2、投注的注单只有在单盘结束后才被视为有效。</p>
    <p>
      3、如果球员在某盘未结束前退出或被取消资格，所有投注此盘的注单将被取消。
    </p>
    <p>
      <br />总得分-大/小<br />1、预测提名的球员在某一特定的时间段内的个人总得分将大于或小于盘口指定的总分数线。
    </p>
    <p>2、提供的时间段可能包含局，单盘及整场比赛。</p>
    <p>3、注单的结算将根据ATP / WTA公布的结果为准。</p>
  </div>
</template>
